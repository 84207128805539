const OFWsvg = ({ size, fill }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 895.76 784.32"
      width={size}
      className="ofw"
    >
      <title>ofw build</title>
      <path
        d="M-3573.58-1444.12c-2.51,14.42-5.46,28.77-7.46,43.26-11.58,83.71-22.88,167.45-34.41,251.17-2.56,18.61-5.36,37.2-8.63,55.69-0.92,5.23-3.64,10.22-6,15.09-3.2,6.59-8.83,9.62-16.08,8.76s-12-5.43-13.45-12.47c-2.76-13.06-5-26.24-7.5-39.34-6.09-31.28-13.38-62.38-18.15-93.85-8.74-57.67-16-115.58-23.92-173.37-5.61-40.78-11.59-81.5-17-122.31-1.79-13.5-2.53-27.15-3.32-40.76-1.7-29.3,21.55-34.14,39.55-29.73,10.22,2.5,14.87,11,16.41,20.55,2.25,14,4.07,28.08,5.06,42.2,3.61,51.7,6.47,103.46,10.26,155.15,3.39,46.24,7.61,92.42,11.49,138.62,0.08,0.94.37,1.87,2.1,2.82,1.35-10.11,2.55-20.23,4.07-30.32,9.28-61.71,17.45-123.63,28.36-185.05,7.18-40.42,18.17-80.18,27.84-120.14,2.76-11.41,8.93-16.15,17.82-15.81s14.41,5.6,16.55,17.16c9.26,49.84,18.09,99.75,27.52,149.55,7.34,38.77,15.34,77.42,24.61,116.06,1.09-13.37,2.38-26.73,3.25-40.12,4.49-69.69,8.22-139.43,13.51-209.06,4.17-54.92,10.33-109.68,15.38-164.53,1.53-16.57,2.25-33.21,3.46-49.81,1.08-14.81,8.37-21,23.06-19.73,4.35,0.37,8.73.54,13,1.18,18,2.64,23.72,9.61,21.83,27.64-2,19.25-5,38.39-7.06,57.63-5.43,50.7-10.07,101.5-16,152.14-5.63,48.19-12.91,96.18-18.69,144.35-6.75,56.29-13.25,112.63-18.73,169.05-3.72,38.25-5.44,76.69-8.17,115-0.59,8.28-5.58,13.9-12.61,15-7.72,1.23-14.59-2.7-17.18-11.82-5.29-18.59-10.51-37.27-14.32-56.21-15.52-77.2-30.56-154.5-45.63-231.79-3.34-17.11-6.1-34.33-9.13-51.5Z"
        transform="translate(4191.9 1854.33)"
        
      />
      <path
        d="M-4191.9-1490.57c1.09-59.49,15.06-125.12,53-184.17,14.75-23,32.82-42.94,55.95-57.76,52.22-33.47,111.3-27.28,155.77,16,27.87,27.14,46,60.25,56.78,97.13,28.87,98.44,21.67,194.15-25,286-15.76,31-37.52,57.63-67.2,76.73-51.36,33.07-109.38,27.6-153.95-14.32-30.07-28.29-48-63.75-59.95-102.65C-4187.24-1408.58-4191.75-1444.44-4191.9-1490.57Zm56.48,31.66c2.47,15.43,5.15,43.22,11.67,70.07,7.09,29.17,21.38,55.41,43.38,76.69,28.59,27.64,64.19,29.57,95.36,4.85a121.87,121.87,0,0,0,19.45-18.64c38.63-48.11,55.76-104.18,55.65-164.89,0-25.55-6.2-51.35-11.71-76.56a159.09,159.09,0,0,0-37.23-72.8c-19.34-21.62-43.25-28-71.2-21.14-30.61,7.46-51.89,27-67.92,52.8C-4124-1566.55-4133.74-1520-4135.42-1458.91Z"
        transform="translate(4191.9 1854.33)"
        
      />
      <path
        d="M-3761.16-1662.57c14.35-.65,27.93-1.51,41.51-1.84q44.59-1.11,89.19-1.75c15.36-.21,22.44,8.45,19.92,23.65-2.92,17.61-11.35,25.87-29.28,25.52-39-.75-78-2.48-116.95-3.84-3.23-.11-6.46-0.42-10.63-0.71-1.75,13.08-3.6,25.66-5.11,38.29-7.72,64.7-15.59,129.38-22.94,194.12-6.46,56.89-11.7,113.92-18.68,170.75-3.71,30.25-9.91,60.2-15.1,90.27a167.64,167.64,0,0,1-4.41,20.72c-2.64,8.61-8.71,13-15.44,11.72-8.23-1.51-11-7.66-10.94-15.05,0.11-23.72-.18-47.48,1-71.16,2.59-52.3,4.85-104.67,9.34-156.82,5.91-68.71,13.7-137.26,20.93-205.85,2.54-24.13,6-48.16,8.58-72.29,0.29-2.71-1.43-6.63-3.55-8.43-10.74-9.09-9.22-21.81,3.44-28.2,2.47-1.24,5.42-4.14,5.75-6.6,5.72-42.09,11.05-84.23,16.61-127.39-10.53-.85-21-1.43-31.46-2.65a31.58,31.58,0,0,1-12.27-3.79c-12.07-7.22-11.57-22,.7-28.94,19.91-11.25,42.1-14.57,64.16-18,78.7-12.26,158-14.4,237.45-13.18,15.81,0.24,31.63.95,47.42,1.95,20.52,1.3,26.92,12.22,18.25,31-4.57,9.89-10,19.39-14.73,29.2-5.88,12.15-15.49,17.78-28.86,17.52-33.28-.66-66.57-0.77-99.82-2-39.24-1.47-78.46-3.83-117.67-6-4.78-.26-7.63.48-8.51,5.84-5.86,36-11.92,72.06-17.86,108.09A42.8,42.8,0,0,0-3761.16-1662.57Z"
        transform="translate(4191.9 1854.33)"
        
      />
      <path
        d="M-3384.08-1301.16c3-57.76,5.5-115.56,9.11-173.29,4.13-66.15,9.23-132.23,13.92-198.35,0.29-4.08.43-8.19,1-12.23,1.92-13,12.91-19,25.3-15.08,19.34,6.05,32.54,18.4,36.93,37.84,2.4,10.61,1.91,22.39.31,33.29-8.75,59.6-18.68,119-27.35,178.65-8,54.77-14.62,109.72-22.46,164.51-1.28,8.94-5.2,17.65-8.82,26.06-3,7-9.37,9.88-16.93,8.33-7.24-1.49-11.61-6.49-11.9-13.76-0.47-12-.13-24-0.13-36h1Z"
        transform="translate(4191.9 1854.33)"
        
      />
      <path
        d="M-3374.37-1222.14c12.64,0.23,22.26,11.33,22.15,25.58s-11.67,26.91-24.55,26.78c-12.72-.13-23.68-12.76-23.25-26.79S-3387.84-1222.39-3374.37-1222.14Z"
        transform="translate(4191.9 1854.33)"
        
      />
    </svg>
  );
};

export default OFWsvg;
